import * as React from 'react';

import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/layout';
import { Button } from '../components/ui/button/button';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Layout className="main__404">
      <Helmet>
        <title>{t('pages.404.title')}</title>
      </Helmet>
      <section className="page404">
        <h1>{t('pages.404.title')}</h1>
        <p>{t('pages.404.text')}</p>
        <div style={{ margin: '40px 0' }}>
          <Button
            text={t('pages.home.title')}
            link={t('navigation.home.url')}
            variant="inverted"
          />
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
